import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { Component, Watch } from 'vue-property-decorator'
import { GChart } from 'vue-google-charts'
import { mapState, mapGetters } from 'vuex'
import Container from 'typedi'
import moment from 'moment'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import GtrEditEventModal from './edit/edit.view.vue'
import GtrEventDuplicationWizard from '@/modules/common/components/ui-core/gtr-event-duplication-wizard/gtr-event-duplication-wizard.vue'

@Component({
  name: 'GtrEventInfoView',
  components: {
    GChart,
    'gtr-edit-event-modal': GtrEditEventModal,
    'gtr-event-duplication-wizard': GtrEventDuplicationWizard
  },
  computed: {
    ...mapState('event', ['event', 'event_stats', 'eventAllContent', 'stats_overview']),
    ...mapState('module', ['activatedEventModules', 'gtrModules']),
    ...mapState('registration', ['registration']),
    ...mapGetters('event', ['moduleStats', 'eventTips', 'eventTimezone'])
  }
})
export default class GtrEventInfoView extends GtrSuper {
  event?: Record<string, any>

  event_stats?: Record<string, any>

  activatedEventModules?: Record<string, any>;
  gtrModules?: Record<string, any>;
  moduleStats!: Record<string, any>;
  eventTips!: Record<string, any>;
  eventTimezone!: string;

  data () {
    return {
      payment_processor_currency: null,
      displayDuplicationWizard: false,
      showEventDeletionReason: false,
      event_all_content: {},
      eventStats: {},
      activation_code: null,
      printer_code: null,
      printer_code_usages: [],
      printerCodeLoading: false,
      printerCodeUsagesHeaders: [
        { text: 'Machine ID', value: 'machine_id', sortable: false },
        { text: '', value: 'actions', sortable: false }
      ],
      geoChartOptions: {
        height: 233
      },
      lineChartOptions: {
        chartArea: {
          width: '85%'
        },
        legend: {
          position: 'bottom'
        },
        pointSize: 5,
        vAxis: {
          gridlines: {
            count: 0
          }
        },
        colors: ['#AEE8B1', '#FFCB9D', '#FFAABA']
      },
      editModalOpen: false,
      printerCodeUsagesModalOpen: false
    }
  }

  created () {
    this.fetchEvent()
    this.fetchEventAllContent()
    this.fetchEventStats()
    this.getEventCode()
    this.getPrinterCode()
  }

  activated () {
    this.fetchEvent()
    this.fetchEventAllContent()
    this.fetchEventStats()
    this.getEventCode()
    this.getPrinterCode()
  }

  shouldDisplayLinkForStat (stat: string): boolean {
    return ['complete', 'incomplete', 'printed'].includes(stat)
  }

  getRouteForLink (stat: string) {
    if (['complete', 'incomplete'].includes(stat)) {
      return 'level-two.event.attendees.search'
    } else if (['printed'].includes(stat)) {
      return 'level-two.event.attendees.badge-print-status.search'
    }
  }

  getParamsForLink (stat: string) {
    if (['complete', 'incomplete'].includes(stat)) {
      return { search_field: 'status', search_value: `${stat.charAt(0).toUpperCase()}${stat.slice(1)}` }
    } else if (['printed'].includes(stat)) {
      return { badge_print_status: `${stat.toUpperCase()}` }
    }
    return {}
  }

  closeDuplicationWizard () {
    this.$data.displayDuplicationWizard = false
  }

  toggleEventDuplicationWizard () {
    this.$data.displayDuplicationWizard = true
  }

  async fetchEvent () {
    try {
      await this.$store.dispatch('event/fetchEvent', this.$route.params.event_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  @Watch('eventAllContent', { immediate: true })
  onEventAllContentChange (value: any) {
    if (value) {
      this.$data.event_all_content = value
      this.$data.payment_processor_currency = this.$data.event_all_content.payment_processor_currency
    }
  }

  private async fetchEventAllContent () {
    try {
      await this.$store.dispatch('event/getEventAllContent', this.$route.params.event_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchEventStats () {
    try {
      const { event_uuid } = this.$route.params
      await this.$store.dispatch('event/fetchStatsSummary', event_uuid)
      await this.$store.dispatch('event/getEventStats', event_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  get activeLinks () {
    const links = [
      {
        text: 'Design Event Website',
        name: 'level-two.modules.registration.design',
        icon: 'mdi-format-color-fill',
        active: false,
        permissions: ['register.design.view']
      },
      {
        text: 'Add & Edit Sessions',
        name: 'level-two.modules.track.sessions',
        icon: 'mdi-account-supervisor-circle-outline',
        active: this.activatedEventModules?.SESSION_TRACKING?.enabled,
        permissions: ['track.sessions.view']
      },
      {
        text: 'Add & Remove Modules',
        name: 'level-two.event.modules',
        icon: 'mdi-view-module',
        active: false,
        permissions: ['events.enable_modules.view']

      },
      {
        text: 'Manage Participants',
        name: 'level-two.event.attendees',
        icon: 'mdi-account-multiple',
        active: true,
        permissions: ['general.participants.view']
      },
      {
        text: 'Event Settings',
        name: 'level-two.event.setting',
        icon: 'mdi-cog',
        active: false,
        permissions: ['general.settings.view']
      },
      {
        text: 'Webhooks',
        name: 'level-two.event.webhooks',
        icon: 'mdi-newspaper',
        active: false,
        permissions: ['general.webhooks.view']
      },
      {
        text: 'Add & Edit Integrations',
        name: 'level-two.event.syncs',
        icon: 'mdi-sync',
        active: true,
        permissions: ['general.integrations.view']
      },
      {
        text: 'Emails',
        name: 'level-two.event.emails',
        icon: 'mdi-email',
        active: true,
        permissions: ['general.emails.view']
      },
      {
        text: 'Reports',
        name: 'level-two.event.reports',
        icon: 'mdi-view-list',
        active: true,
        permissions: ['general.reports.view']
      },
      {
        text: 'Upload Images or Files',
        name: 'level-two.event.files',
        icon: 'mdi-file',
        active: true,
        permissions: ['general.files.view']
      },
      {
        text: 'Promote',
        name: 'level-two.modules.registration.promote',
        icon: 'mdi-chart-line-variant',
        active: this.activatedEventModules?.PROMOTE?.enabled,
        permissions: ['register.promote.view']
      }
    ]

    return links.filter(link => link.active)
  }

  get dashboardModules () {
    if (this.gtrModules) {
      const whitelist = [
        'REGISTRATION',
        'LEADS',
        'SESSION_TRACKING',
        'BADGES',
        'SURVEYS'
      ]

      return this.gtrModules.filter((mod) => whitelist.includes(mod.identifier)).sort((a, b) => whitelist.indexOf(a.identifier) - whitelist.indexOf(b.identifier))
    }
  }

  get filteredModuleStats () {
    if (this.moduleStats) {
      const filteredStats = this.moduleStats
      delete filteredStats.BADGES['to be printed']
      return filteredStats
    }
  }

  get tipCards () {
    return [
      {
        icon: 'mdi-account-plus',
        title: 'Add Participants',
        description: 'Looks like you don\'t have any participants yet.',
        action: {
          label: 'Add participants',
          to: {
            name: 'level-two.event.attendees',
            meta: {
              permissions: 'general.participants.view'
            }
          }
        },
        display: this.eventTips && !this.eventTips.HAS_PARTICIPANTS,
        actions: null
      }, {
        icon: 'mdi-account-supervisor',
        title: 'Add Sessions',
        description: 'Looks like you don\'t have any sessions.',
        action: {
          label: 'Add sessions',
          to: {
            name: 'level-two.modules.track.sessions',
            meta: {
              permissions: 'track.sessions.view'
            }
          }
        },
        display: this.eventTips && !this.eventTips.HAS_SESSION && this.activatedEventModules?.SESSION_TRACKING?.enabled,
        actions: null
      }, {
        icon: 'mdi-file-document',
        title: 'Build your Registration forms',
        description: 'Create and customize registration forms to your event\'s needs.',
        action: {
          label: 'Form builder',
          to: {
            name: 'level-two.modules.registration.form',
            meta: {
              permissions: 'register.forms.view'
            }
          }
        },
        display: this.eventTips && !this.eventTips.HAS_FORM && this.activatedEventModules?.REGISTRATION?.enabled,
        actions: null
      }, {
        icon: 'mdi-sync',
        title: 'Integrate your workflow',
        description: 'Add an integration to connect to your favorite tools and put your event data where you need it.',
        action: {
          label: 'Add integrations',
          to: {
            name: 'level-two.event.syncs',
            meta: {
              permissions: 'general.integrations.view'
            }
          }
        },
        display: this.eventTips && !this.eventTips.HAS_SYNC,
        actions: null
      }, {
        icon: 'mdi-cog',
        title: 'Review Registration Settings',
        description: 'Customize your registration process',
        action: {
          label: 'Registration Settings',
          to: {
            name: 'level-two.modules.registration.settings',
            meta: {
              permissions: 'general.settings.view'
            }
          }
        },
        display: this.eventTips && !this.eventTips.SETTING_CHANGED && this.activatedEventModules?.REGISTRATION?.enabled,
        actions: null
      }, {
        icon: 'mdi-format-color-fill',
        title: 'Design your Registration Site',
        description: 'Style your registration site to match your event branding.',
        action: {
          label: 'Start designing',
          to: {
            name: 'level-two.modules.registration.design',
            meta: {
              permissions: 'register.design.view'
            }
          }
        },
        display: this.eventTips && !this.eventTips.DESIGN_CHANGED && this.activatedEventModules?.REGISTRATION?.enabled,
        actions: null
      }
    ]
  }

  get tips () {
    return this.tipCards.some((tip) => tip.display)
  }

  handleDeleteEvent () {
    this.$data.showEventDeletionReason = true
  }

  async handleDeleteEventAction (payload: any) {
    if (payload.confirm) {
      await this.$store.dispatch('event/removeEvent', { data: { deletion_reason: payload.reason.reason }, event_uuid: this.$route.params.event_uuid })
      Container.get(Notification).success('Event successfully deleted.')
      this.$bus.$emit('gtr-event-created')
      this.$router.push({ name: 'level-two.company.events', params: { uuid: this.$route.params.uuid } })
    }
    this.$data.showEventDeletionReason = false
  }

  private async getEventCode () {
    try {
      this.$data.activation_code = await this.$store.dispatch('event/loadEventCode', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async getPrinterCode () {
    try {
      this.$data.printerCodeLoading = true
      const response = await this.$store.dispatch('event/loadPrinterCode', { event_uuid: this.$route.params.event_uuid })
      this.$data.printer_code = response.code
      this.$data.printer_code_usages = response.usage
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.printerCodeLoading = false
    }
  }

  private async deletePrinterCodeUsage (uuid: string) {
    try {
      this.$data.printerCodeLoading = true
      await this.$store.dispatch('event/deletePrinterCodeUsage', {
        event_uuid: this.$route.params.event_uuid,
        printer_code_usage_uuid: uuid
      })
      this.getPrinterCode()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
      this.$data.printerCodeLoading = false
    }
  }

  isModuleActivatable (identifier: string): { activatable: boolean; message?: string } {
    if (identifier === 'SURVEYS' && (!this.activatedEventModules || (this.activatedEventModules && !this.activatedEventModules.SESSION_TRACKING.enabled))) {
      return { activatable: false, message: 'Attendance Tracking must be enabled.' }
    }
    return { activatable: true }
  }

  async activateModule (identifier: string): Promise<void> {
    if (this.event) { // must be an event.
      await this.$store.dispatch('module/activateModule', {
        event_uuid: this.event.uuid,
        body: {
          module: identifier
        }
      })
    } else {
      Container.get(Notification).error('Event is `undefined`')
    }
  }

  onCopy () {
    Container.get(Notification).success('Successfully copied link.')
  }

  onError () {
    Container.get(Notification).success('There was an error copying the link. Please refresh and try again.')
  }

  get regUrl () {
    if (this.$data.event_all_content) {
      return `${process.env.VUE_APP_REG_URL}/${this.$data.event_all_content.event.event_identifier}`
    }
  }

  get totalPayments () {
    if (this.$data.eventStats) {
      const charges = this.$data.eventStats?.paymentTypeTotals.Charge || 0
      const voids = this.$data.eventStats?.paymentTypeTotals.Void || 0
      const refunds = this.$data.eventStats?.paymentTypeTotals.Refund || 0
      return (charges + voids + refunds)
    }
  }

  get geoChartData () {
    const regionStats = this.$data.eventStats.analytics_regions
    if (!regionStats) {
      return [['No Data Available']]
    }
    // since data that gets returned from BE has multiple arrays of the same countries,
    // we need to restructure and total all multiples into one array per country
    const geoChart = [['Country', 'Popularity']]
    const countryStats: any[] = []

    // find all countries and push into array with no repeats
    const countries: any[] = []
    regionStats.forEach(stat => {
      if (stat.country === null) {
        // is this manipulating too far?
        stat.country = 'United States'
      }
      if (!countries.includes(stat.country)) {
        countries.push(stat.country)
      }
    })
    // in separate array, total up popularity of each country
    const totals: any[] = []
    regionStats.forEach(stat => {
      const i = countries.indexOf(stat.country)
      if (totals[i] === undefined) {
        totals[i] = stat.total
      } else {
        totals[i] += stat.total
      }
    })

    for (let i = 0; i < countries.length; i++) {
      countryStats.push([countries[i], totals[i]])
    }

    countryStats.sort((a: any[], b: any[]) => b[1] - a[1]) // sort highest to lowest

    return geoChart.concat(countryStats)
  }

  get lineChartData () {
    const regCounts = this.$data.eventStats.registrationCountsByDate
    if (!regCounts) {
      return [['No Data Available']]
    }
    const lineChart = [['Date', 'Complete', 'Incomplete', 'Canceled']]
    for (const date in regCounts) {
      if (date === '' || date === null) {
        lineChart.push([
          date,
          regCounts[date].Complete || 0,
          regCounts[date].Incomplete || 0,
          regCounts[date].Canceled || 0
        ])
      } else {
        lineChart.push([
          moment(date).format('MM-DD-YYYY'),
          regCounts[date].Complete || 0,
          regCounts[date].Incomplete || 0,
          regCounts[date].Canceled || 0
        ])
      }
    }
    return lineChart
  }

  @Watch('event_stats', { immediate: true })
  onEventStatsChange (event_stats: any) {
    this.$data.eventStats = event_stats
  }

  get paymentsExportFile () {
    const totals = this.event_stats?.fee_totals
    const csvString = `Registration Fees,"${this.$options?.filters?.toCurrency(totals?.registrationTypeOptionTotal)}"\nLine Items,"${this.$options?.filters?.toCurrency(totals?.nonRegistrationTypeOptionTotal)}"\n`
    const csvFile = new File([csvString], 'payments.csv', { type: 'text/csv' })
    return URL.createObjectURL(csvFile)
  }

  get regionalRegistrationsExportFile () {
    const regions = this.event_stats?.analytics_regions
    let csvString = 'Country,State/Province,# of Registrations\n'

    for (const region of regions) {
      csvString += region.country + ',' + region.state + ',"' + region.total.toLocaleString() + '"\n'
    }

    const csvFile = new File([csvString], 'registrations_by_region.csv', { type: 'text/csv' })
    return URL.createObjectURL(csvFile)
  }

  get registrationStatusExportFile () {
    const statuses = this.event_stats?.statusBreakdown
    let csvString = ''

    for (const status in statuses) {
      csvString += status + ',"' + statuses[status].toLocaleString() + '"\n'
    }

    const csvFile = new File([csvString], 'registrations_by_status.csv', { type: 'text/csv' })
    return URL.createObjectURL(csvFile)
  }

  handleEditModalClose () {
    this.$data.editModalOpen = false
  }
}
